<template>
    <section>
        <div v-if="id_producto" class="custom-scroll overflow-auto" :class="rutaCedis ? 'height-cedis' : $route.name == 'tendero.productos' ? 'height-leechero' : 'height-leechero-perfil'">
            <template v-if="admin">
                <div class="row mx-2 my-2 py-2 px-2 bg-info-stock br-12">
                    <div class="col px-0">
                        <div class="row mx-0 text-general">
                            <div class="col pl-0">
                                <p class="tres-puntos f-17 f-600">
                                    <i class="icon-cash text-general f-17 mr-2" />
                                    {{ convertMoney(card.total_valor,card.id_moneda) }}
                                </p>
                            </div>
                            <div class="col pl-0">
                                <p class="tres-puntos f-17 f-600">
                                    <i class="icon-package-variant-closed text-general f-25" />
                                    {{ agregarSeparadoresNumero(card.unidades,producto.cantidad_tipo == 1 ? 0 : 2) }} Uni
                                </p>
                            </div>
                        </div>
                        <div class="row mx-0 py-2 text-g">
                            <div class="col text-general d-middle pl-1">
                                <i class="icon-cedis text-general f-20" />
                                <div class="col pl-2">
                                    <p class="text-general f-600">{{ card.stock_minimo_cedis }}</p>
                                    <p class="text-general f-14">Stock Min Cedis</p>
                                </div>
                            </div>
                            <div class="col pl-1 text-general d-middle">
                                <i class="icon-leechero-cap text-general f-20" />
                                <div class="col pl-2">
                                    <p class="text-general f-600">{{ card.stock_minimo_tienda }}</p>
                                    <p class="text-general f-14">Stock Min {{ $config.vendedor }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <i class="icon-calendar-sync-outline text-general f-25" />
                            <div class="col pl-2 text-general">
                                <p class="text-general f-17 f-600">
                                    {{ separadorNumero(card.rotacion,2, false) }} Uni.
                                </p>
                                <p class="text-general f-14">Rotación diaria últ. mes</p>
                            </div>
                            <div class="col pl-2 d-middle">
                                <el-tooltip placement="bottom" content="Permite Vender sin Stock" effect="light">
                                    <i class="icon-cash-lock-open f-18 text-general mt-2 ml-auto" />
                                </el-tooltip>
                                <div v-if="admin && $can('boton_productos_editar_stock_minimo') && edicionDisponible" class="btn-editar-stock mx-2 border br-8 text-general cr-pointer" @click="edicionStock()">
                                    <i class="icon-pencil-outline f-20" />
                                </div>
                                <template v-if="admin && $can('boton_productos_añadir_stock')">
                                    <div class="btn-agregar-stock br-8 text-white cr-pointer d-middle-center" @click="anadirCompra()">
                                        <i class="icon-plus f-15" />
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- <p>
                            <el-tooltip placement="bottom" content="Rotacion Diaria durante el ultimo mes" effect="light">
                                <i class="icon-info-circled-alt f-18" />
                            </el-tooltip>
                        </p> -->
                        <!-- <p v-if="card.cedis_inventario">
                            Inventario almacenado en cedis: {{ card.cedis_inventario.nombre }}
                        </p> -->
                        <!-- <div class="col-12 d-flex flex-column justify-content-center align-items-center" /> -->
                    </div>
                </div>
            </template>
            <template v-else-if="!id_tienda_ruta">
                <div class="row mx-2 my-2 py-2 px-2 bg-whitesmoke2 br-12">
                    <div class="col px-0 py-2">
                        <div class="row mx-0">
                            <div class="col px-0">
                                <p class="tres-puntos">
                                    {{ convertMoneyTendero(card.total_valor,card.id_moneda) }}
                                </p>
                            </div>
                            <div class="col-auto">
                                <el-tooltip placement="bottom" content="Permite Vender sin Stock" effect="light">
                                    <i class="icon-cash-lock-open f-20 text-gr-general" />
                                </el-tooltip>
                            </div>
                        </div>
                        <p class="tres-puntos pl-3">
                            {{ agregarSeparadoresNumero(card.unidades,producto.cantidad_tipo == 1 ? 0 : 2) }} Uni
                        </p>
                        <p class="pl-3">
                            {{ card.stock_minimo_tienda }} Stock Min leechero
                        </p>
                        <p class="pl-3">
                            {{ separadorNumero(card.rotacion,2, false) }} Rotacion
                            <el-tooltip placement="bottom" content="Rotacion Diaria durante el ultimo mes" effect="light">
                                <i class="icon-info-circled-alt f-18" />
                            </el-tooltip>
                        </p>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="row mx-2 my-2 py-2 px-2 bg-whitesmoke2 br-12">
                    <div class="col px-0 py-2">
                        <div class="row mx-0">
                            <p class="col tres-puntos px-0">
                                {{ agregarSeparadoresNumero(card.unidades,producto.cantidad_tipo == 1 ? 0 : 2) }} Uni
                            </p>
                            <p class="tres-puntos col px-0">
                                {{ convertMoney(card.total_valor,card.id_moneda) }}
                            </p>
                            <div class="col-auto">
                                <el-tooltip placement="bottom" content="Permite Vender sin Stock" effect="light">
                                    <i class="icon-cash-lock-open f-20 text-gr-general" />
                                </el-tooltip>
                            </div>
                        </div>
                        <p class="px-0">
                            {{ separadorNumero(card.rotacion,2, false) }} Rotacion
                            <el-tooltip placement="bottom" content="Rotacion Diaria durante el ultimo mes" effect="light">
                                <i class="icon-info-circled-alt f-18" />
                            </el-tooltip>
                        </p>
                    </div>
                </div>
            </template>
            <div class="row mx-0 mt-3">
                <div class="col-12 px-0 text-center">
                    Ventas en los últimos 60 días
                    <chartBarras v-loading="loading" :handle-resize="showTabStocks" :datos="extra" :chart="chart_ventas" />
                </div>
            </div>
            <div class="row mx-0">
                <div class="col-12 text-center">
                    Compras en los últimos 60 días
                    <chartBarras v-loading="loading" :handle-resize="showTabStocks" :datos="extra" :chart="chart_compras" />
                </div>
            </div>

            <div class="row mx-0">
                <div class="col-12">
                    <el-table
                    id="cont-scroll"
                    stripe
                    :data="tableData"
                    :columnas="dataColumnas"
                    class="cr-pointer"
                    style="width: 100%"
                    @row-click="informacionCOmpra"
                    >
                        <el-table-column label="Cantidad" class-name="text-center f-14 text-general" min-width="100">
                            <template slot-scope="scope">
                                <p class="cr-pointer">
                                    {{ agregarSeparadoresNumero(scope.row.cantidad, producto.cantidad_tipo == 1 ? 0 : 2) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="admin" prop="valor" label="Valor U." sortable min-width="100" class-name="f-14 text-general">
                            <template slot-scope="scope">
                                {{ convertMoney(scope.row.valor, scope.row.id_moneda) }}
                            </template>
                        </el-table-column>
                        <el-table-column v-else-if="!id_tienda_ruta" prop="valor" label="Valor U." sortable min-width="100" class-name="f-14 text-general">
                            <template slot-scope="scope">
                                {{ convertMoneyTendero(scope.row.valor, scope.row.id_moneda) }}
                            </template>
                        </el-table-column>
                        <el-table-column v-else prop="valor" label="Valor U." sortable min-width="100" class-name="f-14 text-general">
                            <template slot-scope="scope">
                                {{ convertMoney(scope.row.valor, scope.row.id_moneda) }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="fecha_pago" label="Fecha" sortable min-width="120" class-name="f-14 text-general">
                            <template slot-scope="scope">
                                {{ scope.row.created_at | helper-fecha('DD/MM/YYYY') }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="vencimiento" label="Vencimiento" sortable min-width="140" class-name="f-14 text-general">
                            <template slot-scope="scope">
                                <span :class="`${scope.row.indicador_vencimiento ? 'text-red':'' }`">
                                    {{ scope.row.vencimiento | helper-fecha('DD/MM/YYYY') }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Restantes" min-width="100" class-name="text-center f-14 text-general">
                            <template slot-scope="scope">
                                <p class="cr-pointer">
                                    {{ agregarSeparadoresNumero(scope.row.cantidad_restante, producto.cantidad_tipo == 1 ? 0 : 2) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column v-if="admin" prop="nombre_proveedor" label="Proveedor" min-width="100" class-name="f-14 text-general">
                            <template slot-scope="scope">
                                {{ scope.row.nombre_proveedor || '-------' }}
                            </template>
                        </el-table-column>
                        <el-table-column width="80" class-name="text-center text-general">
                            <template slot-scope="scope">
                                <el-tooltip v-if="scope.row.eliminar && $can('boton_productos_eliminar_ultimo_stock') && admin" placement="bottom" content="Anular stock" effect="light">
                                    <i class="icon-trash f-22 text-red" @click.stop="anularStock(scope.row)" />
                                </el-tooltip>
                                <el-tooltip v-if="scope.row.cant_ajuste" placement="bottom" content="Registra movimientos" effect="light">
                                    <i class="icon-attention-alt f-22 text-red" />
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="row mx-0 py-3 justify-content-xl-center justify-content-lg-center">
                        <!-- <el-pagination
                        class="paginar-general"
                        layout="prev, pager, next"
                        :total="20"
                        /> -->
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$usuario.rol == 1 || $usuario.rol == 3" class="row mx-0 align-items-center pt-1 my-2 border-top" style="height:40px">
            <div v-if="id_producto && $can('botones_productos_gestionar_stock')" class="col">
                <div class="btn-general" @click="retiroManual()">
                    Retirar
                </div>
            </div>
            <div v-show="id_tienda_ruta && $can('botones_productos_gestionar_stock')" class="col">
                <div class="btn-general" @click="devolucion()">
                    Devolver
                </div>
            </div>
            <div v-show="id_cedi_ruta && id_producto && $can('botones_productos_gestionar_stock')" class="col">
                <div class="btn-general" @click="trasladar()">
                    Trasladar
                </div>
            </div>
        </div>
        <div v-if="!id_producto" class="row h-100 mx-0 align-content-center justify-center">
            <div class="col-9 text-center">
                <div class="row mx-0 justify-center f-60">
                    <img src="/img/no-imagen/click.svg" alt="" style="width:115px;height:115px;" />
                </div>
                <p class="mb-0 text-muted2 f-17 f-600">Selecciona un producto del panel de la izquierda. </p>
            </div>
        </div>
        <!-- Partials -->
        <modal-edicion-stock ref="EdicionStock" @actualizar="actualizarCard" />
        <modal-anadir-compra ref="AnadirCompra" @actualizar="listarGraficas" />
        <modal-informacion-compra ref="InformacionCompra" :admin="admin" />
        <modal-retiro-manual ref="modalRetiroManual" @actualizar="listarGraficas" />
        <modal-devolucion ref="modalDevolucion" @actualizar="listarGraficas" />
        <modal-trasladar-producto ref="modalTrasladar" @actualizar="listarGraficas" />
        <modalEliminar ref="modalAnularStock" :titulo="titulo_eliminar" @eliminar="accionAnularStock">
            <div class="row mx-0 mb-2 justify-center text-center">
                {{ msg_eliminar }}
            </div>
        </modalEliminar>
    </section>
</template>

<script>
import ProductosStocks from '~/services/productos/productos_stocks'
import Productos from "~/services/productos/productosAdmin"

import {mapGetters} from 'vuex'
import Vue from 'vue'
import echarts from 'echarts'
import moment from 'moment'
Vue.component('echarts',echarts);

// let colorGeneral = getComputedStyle(document.documentElement) .getPropertyValue('--color-general')
let colorGeneral2 = getComputedStyle(document.documentElement) .getPropertyValue('--color-db')

let bgColor = '#fff', fontColor = '#666';

let lineOption = {
    lineStyle: {
        color: 'rgba(151,151,151,0.5)',
        type: 'solid'
    }
}
export default {

    components: {
        modalEdicionStock: () => import('../partials/modalEdicionStock'),
        modalAnadirCompra: () => import('../partials/modalAnadirCompra'),
        modalInformacionCompra: () => import('../partials/modalInformacionCompra'),
        modalRetiroManual: () => import('../partials/modalRetiroManual'),
        modalDevolucion: () => import('../partials/modalDevolucion'),
        modalTrasladarProducto: () => import('../partials/modalTrasladarProducto'),
    },
    props:{
        admin:{
            type: Boolean,
            default: true
        },
        showTabStocks:{
            type: Boolean,
            default: false
        }
    },
    data(){
        let self = this
        return {
            id_cedi_ruta: parseInt(this.$route.params.id_cedis),
            id_tienda_ruta: parseInt(this.$route.params.id_tienda),
            loading: false,
            producto:{},
            card: {
                cedis_inventario: {
                    nombre:''
                },
                unidades: 0,
                total_valor: 0,
                stock_minimo_cedis: 0,
                stock_minimo_tienda: 0,
                rotacion: 0,
                venta_sin_stock: 0

            },
            dataColumnas: [
                'cantidad', 'cantidad_restante','created_at_format','valor'
            ],
            grafica_compras:[],
            extra:[],
            tableData: [
            ],
            chart_compras : {
                dataset: {
                    dimensions: ['key', 'value'],
                    source: []
                },
                backgroundColor: bgColor,
                tooltip:{
                    trigger: 'axis',
                    formatter: params => {
                        const color = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params[0].color};"></span>`
                        const data = params[0].data
                        // return `${color} ${data.dia}  : ${data.value}`
                        return `${color} ${data.dia}  : ${self.agregarSeparadoresNumero(data.value, self.producto.cantidad_tipo == 1 ? 0 : 2)}`
                    }
                },
                grid: {
                    top: '14%',
                    // right: '3%',
                    // left: '5%',
                    bottom: '14%'
                },
                xAxis: [{
                    type: 'category',
                    // name: '2 Ago. - 8 Ago.',
                    show: false,
                    nameTextStyle: {
                        color: '#6D6B6B',
                        align: 'right',
                        verticalAlign: "top",
                        lineHeight: 33
                    },
                    axisLine: lineOption,
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        margin: 10,
                        color: fontColor,
                        textStyle: {
                            fontSize: 14
                        },
                    },
                }],
                yAxis: [{
                    name: 'Unidades',
                    nameLocation: 'end',
                    nameTextStyle: {
                        color: '#6D6B6B',
                    },
                    axisLabel: {
                        formatter: '{value}',
                        color: fontColor,
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: lineOption,
                    splitLine: lineOption
                },{
                    axisLine: lineOption,
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show:false
                    }
                }],
                series: [{
                    type: 'bar',
                    // data: sData,
                    barWidth: '5px',
                    itemStyle: {
                        normal: {
                            color: colorGeneral2
                        }
                    }
                }]
            },
            chart_ventas : {
                dataset: {
                    dimensions: ['key', 'value'],
                    source: []
                },
                backgroundColor: bgColor,
                tooltip:{
                    trigger: 'axis',
                    formatter: params => {
                        // console.log(params);
                        const color = `<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${params[0].color};"></span>`
                        const data = params[0].data
                        // return `${color} ${data.dia} : ${this.agregarSeparadoresNumero(data.value)}`
                        return `${color} ${data.dia}  : ${self.agregarSeparadoresNumero(data.value, self.producto.cantidad_tipo == 1 ? 0 : 2)}`

                    }
                },
                grid: {
                    /* top: '14%',
                    // right: '3%',
                    // left: '5%',
                    bottom: '14%' */
                },
                xAxis: [{
                    type: 'category',
                    // name: '2 Ago. - 8 Ago.',
                    show: false,
                    nameTextStyle: {
                        color: '#6D6B6B',
                        align: 'right',
                        verticalAlign: "top",
                        lineHeight: 33
                    },
                    axisLine: lineOption,
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        margin: 10,
                        color: fontColor,
                        textStyle: {
                            fontSize: 14
                        },
                    },
                }],
                yAxis: [{
                    // name: '10 Feb. - 16 Feb.',
                    nameLocation: 'end',
                    name: 'Unidades',
                    nameTextStyle: {
                        color: '#6D6B6B',
                    },
                    axisLabel: {
                        formatter: '{value}',
                        color: fontColor,
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: lineOption,
                    splitLine: lineOption
                },{
                    axisLine: lineOption,
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        show:false
                    }
                }],
                series: [{
                    type: 'bar',
                    // data: sData,
                    barWidth: '5px',
                    itemStyle: {
                        normal: {
                            color: colorGeneral2
                        }
                    }
                }]
            },
            titulo_eliminar: '¿Realmente desea anular este stock del producto?',
            msg_eliminar: 'Esta acción no puede ser revertida.',
            idAnular: null
        }
    },
    computed:{
        ...mapGetters({
            id_producto: 'productos/productos/id_producto',
            id_cedis:'cedis/id_cedis_navbar',
            id_moneda:'cedis/id_moneda',
            infoCedis:'cedis/info_cedis',
        }),
        rutaCedis(){
            return this.$route.name == 'admin.cedis.productos'
        },
        edicionDisponible(){
            return this.infoCedis.id != null && this.infoCedis.id != 0 && this.infoCedis.id_cedis_catalogo === null
        }
    },
    watch:{
        id_producto(val){
            if(val){
                this.grafica_compras= []
                this.listarGraficas()
            }
        },
        tableData(newValue){
            this.$nextTick(()=>{
                let tabla = document.querySelector('#cont-scroll .el-table__body-wrapper')
                console.log(tabla, 'tabla');
                this.scrollInx(tabla)
            })
        }
    },
    methods: {
        edicionStock(){
            this.$refs.EdicionStock.toggle(this.card, this.producto);
        },
        anadirCompra(){
            this.$refs.AnadirCompra.toggle();
        },
        informacionCOmpra(info){
            this.$refs.InformacionCompra.toggle(info, this.card,this.producto.cantidad_tipo);
        },
        actualizarCard(data){
            this.card.stock_minimo_cedis = data.stock_minimo_cedis
            this.card.stock_minimo_tienda = data.stock_minimo_tienda
            this.card.venta_sin_stock = data.venta_sin_stock

        },
        retiroManual(){
            this.$refs.modalRetiroManual.toggle(this.producto);
        },
        devolucion(){
            this.$refs.modalDevolucion.toggle(this.producto);
        },
        trasladar(){
            this.$refs.modalTrasladar.toggle(this.producto);
        },
        anularStock(row){
            console.log(row);
            this.idAnular = row.id
            this.$refs.modalAnularStock.toggle(row);
        },
        async accionAnularStock(){
            try {
                var { data } = await Productos.accionAnularStock(this.idAnular)
                this.notificacion('Mensaje',data.mensaje,'success')
                this.$refs.modalAnularStock.toggle()
                this.listarGraficas()
            } catch (e){
                this.error_catch(e)
            }
        },
        async listarGraficas(){
            try {
                let params = {}
                if(this.$usuario.rol_nombre=== 'tendero'){
                    params.id_cedis = this.$usuario.tienda.id_cedis
                }else{
                    params.id_cedis =this.id_cedi_ruta? this.id_cedi_ruta: this.id_cedis
                }

                if(this.$route.name ==='admin.tiendas.produccion.productos'){
                    params.id_tienda = this.id_tienda_ruta
                    // id_tienda_ruta: parseInt(this.$route.params.id_tienda),
                }else{
                    if(!params.id_cedis || params.id_cedis ==0){
                        return false
                    }
                }
                this.loading = true
                const {data} = await ProductosStocks.listarGraficas(this.id_producto, params)
                this.chart_compras.dataset.source = data.grafica_compras

                // para grafica ventas azul
                this.chart_ventas.dataset.source = data.grafica_ventas

                data.productos_stocks.map((el,i) => {
                    el.eliminar = i == 0 && el.cantidad == el.cantidad_restante
                })

                this.tableData = data.productos_stocks
                this.card = data.card
                this.producto = data.producto

            } catch (e){
                this.error_catch(e)
            } finally {
                setTimeout(() => {
                    this.loading = false
                }, 0);
            }
        },
        scrollInx(element = null){
            if(!element) return
            const slider = element
            let isDown = false;
            let startX;
            let scrollLeft;

            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;

            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');

            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                //console.log(walk);
            });
        },
    }
}
</script>

<style lang="scss" scoped>
.br-12{
    border-radius: 12px;
}
.text-red{
    color: #FF2525;
}
.height-cedis{
    height: calc(100vh - 290px);
}
.height-productos{
    height:calc(100vh - 175px);
}
.height-leechero{
    height:calc(100vh - 112px);
}
.height-leechero-perfil{
    height:calc(100vh - 319px);
}
.bg-info-stock{
    background-color: #F6F9FB !important;
}
.btn-agregar-stock{
    width: 32px;
    height: 32px;
    background-color: var(--color-general) !important;
}
.btn-editar-stock{
    width: 32px;
    height: 32px;
    background-image: linear-gradient(to bottom, #FFFFFF, #F3F5F7) !important;
}
/*-*-*-*-*-* Media Queries -*-*-*-*-*-*/
@media only screen and (min-width : 768px) and (max-width : 1000px) {
    .height-leechero{
        height: calc(100vh - 215px) !important;
    }
}
@media (max-width: 768px){
    .height-leechero{
        height: calc(100vh - 215px) !important;
    }
}
</style>
